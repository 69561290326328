import { render, staticRenderFns } from "./index copy.vue?vue&type=template&id=34179e27&scoped=true&"
import script from "./index copy.vue?vue&type=script&lang=ts&"
export * from "./index copy.vue?vue&type=script&lang=ts&"
import style0 from "./index copy.vue?vue&type=style&index=0&id=34179e27&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34179e27",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionTitle: require('/root/workspace/com.jufair.vue_H3C2/components/SectionTitle/index.vue').default,GoodsItem: require('/root/workspace/com.jufair.vue_H3C2/components/Goods/item.vue').default,Empty: require('/root/workspace/com.jufair.vue_H3C2/components/Empty/index.vue').default,Pagination: require('/root/workspace/com.jufair.vue_H3C2/components/Pagination/index.vue').default})
